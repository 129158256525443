import "./jquery-3.1.1.min.js";
import "./bootstrap.min.js";
import "./jquery.flexslider-min.js";
import "./mobile-detect.min.js";
import "./spin.min.js";
import "./ladda.min.js";
import "./objectFitPolyfill.basic.min.js";

var ismobile = "23";
var $window;
var $header;
var $backgroundVideo;
var $backgroundPic;
var $navbar;
var fadeableContent;
var fadeableFooter;
var $embedResponsive;
var $embedResponsivePic;
var $logo;
var md;
var $contactBox;
var $logoLeft;
var $fontResizeContainer;
var $fbLogoContainer;
var $teaserText;
var intersectionObserver;
jQuery(document).ready(function() {
    md = new MobileDetect(window.navigator.userAgent);
    $window = $(window);
    $header = $("header");
    $backgroundVideo = $(".backgroundvideo");
    $backgroundPic = $(".backgroundpic");
    $logo = $header.find(".logo");
    $embedResponsive = $backgroundVideo.find(".embed-responsive");
    $embedResponsivePic = $backgroundPic.find(".embed-responsive");
    $contactBox = $backgroundVideo.find(".contact--box");
    $fontResizeContainer = $(".font-resize--container");
    $fbLogoContainer = $(".facebook-link");
    $logoLeft = $header.find(".logo--left-scroll");
    $navbar = $("div.navbar");
    $teaserText = $(".teasertext");
    if (window.IntersectionObserver) {
        intersectionObserver = new IntersectionObserver(function(event) {
                event.forEach(function(entry) {
                    if (entry.isIntersecting === true) {
                        entry.target.classList.add("fadeContentIn");
                        intersectionObserver.unobserve(entry.target)
                    }
                })
            }
        )
    }
    windowResize();
    $window.on("resize", function() {
        windowResize()
    });
    $window.on("scroll", function(e) {
        animateMenue(e)
    });
    if (!intersectionObserver) {
        $window.on("scroll", function(e) {
            fadeContent()
        })
    }
    initFadeContent();
    fadeTeasertextIn();
    initFlexsliderTeaser();
    $window.trigger("scroll");
    initFontResizer();
    if (ismobile) {
        initMobileFunctions()
    }
});
function lazyLoad() {
    initFadeContent();
    initKontaktformular();
    $(window).trigger("scroll")
}
function initFadeContent() {
    fadeableContent = $("article").find(".container-content").find(">div,>p,>h1,>h2,>strong").not(".no-fade");
    fadeableFooter = $(".footer").find(".footerinhalt");
    if (!intersectionObserver) {
        $.each(fadeableContent, function(i, v) {
            $(v).data().top = $(v).offset().top
        });
        fadeableFooter.data().top = fadeableFooter.offset().top
    } else {
        $.each(fadeableContent, function(i, v) {
            intersectionObserver.observe(v)
        });
        intersectionObserver.observe(fadeableFooter.get(0))
    }
}
function fadeContent() {
    var offset = $window.scrollTop() + $window.height();
    $.each(fadeableContent, function(i, v) {
        if ($(v).data().top < offset) {
            $(v).addClass("fadeContentIn")
        }
    });
    if (fadeableFooter.data().top < offset) {
        fadeableFooter.addClass("fadeContentIn")
    }
}
function windowResize() {
    if (md.mobile()) {
        if (!ismobile || ismobile === "23") {
            ismobile = true;
            $(".header").css("transform", "")
        }
    } else {
        if (ismobile || ismobile === "23") {
            ismobile = false
        }
    }
}
var minScaleLogo;
function animateMenue(e) {
    if (ismobile)
        return;
    if (!minScaleLogo) {
        var normalHeight = parseInt($logo.height());
        var minHeight = parseInt($navbar.height());
        minScaleLogo = minHeight / normalHeight
    }
    var scrollhoehe = e.currentTarget.scrollY;
    var headerOffset = scrollhoehe / 4;
    if (headerOffset > 70)
        headerOffset = 70;
    var logoStep = 280 - scrollhoehe / 2;
    if (logoStep < 150)
        logoStep = 150;
    $header.css("transform", "translateY(-" + headerOffset + "px)");
    var logoScale = 1 - (1 - minScaleLogo) * (headerOffset * .02);
    if (logoScale < minScaleLogo)
        logoScale = minScaleLogo;
    $logo.css("transform", "scale(" + logoScale + ")");
    var halbeHoehe = (700 < scrollhoehe ? 700 : scrollhoehe) / 5;
    $backgroundPic.css("transform", "translateY(" + halbeHoehe + "px)");
    $backgroundVideo.css("transform", "translateY(" + halbeHoehe + "px)");
    if (headerOffset >= 120) {
        $logoLeft.addClass("visible")
    } else {
        $logoLeft.removeClass("visible")
    }
    var teaserTextOffset = scrollhoehe * .28;
    $teaserText.css("transform", "translateY(" + teaserTextOffset + "px)")
}
function initFlexsliderTeaser() {
    $(".hero-slider").flexslider({
        initDelay: 3e3,
        animationSpeed: 1500,
        slideshowSpeed: 5e3,
        directionNav: false,
        controlNav: false,
        controlsContainer: $(".flexslider__nav"),
        prevText: "",
        nextText: "",
        touch: true,
        start: function() {
            $embedResponsivePic.removeClass("loading");
            setTimeout(function() {
                $(".teaser-box").addClass("visible")
            }, 2e3)
        }
    })
}
function initKontaktformular() {
    var $frmContact = $("#frmContact");
    var $submitBtn = $("#submitBtn");
    $frmContact.submit(function() {
        var l = Ladda.create(document.querySelector("#submitBtn"));
        l.start();
        $.ajax({
            type: "POST",
            url: window.location.href,
            data: $frmContact.serialize()
        }).done(function(msg) {
            l.stop();
            msg = JSON.parse(msg);
            var tmp = $submitBtn.parent().html();
            $submitBtn.parent().html('<div class="alert alert-' + msg.status + ' text-left fade in">' + msg.text + "</div>");
            if (msg.status == "success")
                $frmContact[0].reset();
            setTimeout(function() {
                $(".alert").parent().fadeOut(300, function() {
                    $(".alert").parent().html(tmp);
                    $submitBtn.parent().fadeIn(300)
                })
            }, 1500)
        });
        return false
    })
}
function fadeTeasertextIn() {
    setTimeout(function() {
        $(".tt-1, .tt-2").addClass("fadeInTeasertext")
    }, 2e3)
}
var aktFontSize;
function initFontResizer() {
    if (!aktFontSize) {
        aktFontSize = $("article").css("font-size");
        aktFontSize = parseInt(aktFontSize)
    }
    var $smaller = $fontResizeContainer.find(".font-resize-smaller");
    var $larger = $fontResizeContainer.find(".font-resize-larger");
    $smaller.off("click").on("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
        aktFontSize--;
        if (aktFontSize < 15)
            aktFontSize = 15;
        setFontSize(aktFontSize)
    });
    $larger.off("click").on("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
        aktFontSize++;
        if (aktFontSize > 20)
            aktFontSize = 20;
        setFontSize(aktFontSize)
    })
}
var $article;
function setFontSize(size) {
    if (!$article) {
        $article = $("article")
    }
    $article.css("font-size", size + "px");
    $(".verlinkung-container .verlinkung .verlinkung__content").css("font-size", size + "px");
    $(".news-startseite .news .news__content").css("font-size", size + "px")
}
function initMobileFunctions() {
    $(".mobilemenu__button").off("click").on("click", function() {
        $(".mobilemenu").addClass("open")
    });
    $(".mobilemenu__button-close, .mobilemenu").off("click").on("click", function() {
        $(".mobilemenu").removeClass("open");
        $(".mobilemenu__button-close").css("position", "absolute")
    });
    $(".mobilemenu").on("transitionend", function(e) {
        if (parseInt(getComputedStyle(e.target).right) == 0) {
            $(e.target).find(".mobilemenu__button-close").css("position", "fixed")
        } else {
            $(".mobilemenu").scrollTop(0)
        }
    })
}
